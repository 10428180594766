import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Map, { ScaleControl, NavigationControl, FullscreenControl, Source, Layer, Popup } from 'react-map-gl';
import { withStyles } from '@material-ui/styles';
import turf from 'turf';
import outdoorStyle from '../../style/mapStyles/outdoor.json';
import streetStyle from '../../style/mapStyles/street.json';
import { Box, Checkbox, FormControlLabel, Paper, Switch, Typography } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import SatelliteIcon from '@material-ui/icons/Satellite';
import ChevronUpIcon from '@material-ui/icons/ExpandMore';
import ChevronDownIcon from '@material-ui/icons/ExpandLess';
import {
  clusterCountLayer,
  clusterLayer,
  clusteredPointLayerWarning,
  clusteredPointLayerWarningText,
  clusteredPointLayerSpypoint,
  clusteredPointLayerSpypointCircle,
  clusteredPointLayerSyncError,
  clusteredPointLayerSyncErrorCircle,
  clusteredPointLayerBatteryWarning,
  clusteredPointLayerBatteryWarningCircle,
  unclusteredPointLayer,
  unclusteredPointLayerCountCircle,
  unclusteredPointLayerCount,
  unclusteredPointLayerWarning,
  unclusteredPointLayerWarningText,
  unclusteredPointLayerSyncError,
  unclusteredPointLayerSyncErrorCircle,
  unclusteredPointLayerSpypoint,
  unclusteredPointLayerSpypointCircle,
  unclusteredPointLayerBatteryWarningCircle,
  unclusteredPointLayerBatteryWarning,
  unclusteredPointLayerInsideText
} from './Layers';
import DeviceView from './DeviceView';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import { getDisplayName } from '../../util/deviceHelpers';
import { MINIMUM_BATTERY_VOLTAGE } from '../../util/constants';

mapboxgl.workerClass = MapboxWorker;

const styles = (theme) => ({
  mapActionMenu: {
    position: 'absolute',
    top: 10,
    left: '2vh',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
  },
  centerIcons: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    flexDirection: 'row',
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  },
  topElements: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 310,
    alignItems: 'center'
  },
  layers: {
    margin: '15px',
    padding: '10px',
    border: '2px solid #000',
    borderRadius: '8px',
    textAlign: 'center'
  },
  collapseButton: {
    height: '32px',
    width: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: '#0093C5',
    border: 'none',
    backgroundColor: 'white',
    zIndex: 1000,
    cursor: 'pointer',
    marginLeft: 50
  },
});

const MAPBOX_TOKEN = "pk.eyJ1IjoicGF0cmlja2NvbWJlIiwiYSI6ImNrbHR5eTF0ZTBkbGMycXF4bXc2dWF5bHQifQ.wCfYeiV37t7c9yqZcGQLgQ";

const MapView = (props) => {
  const { classes, points, setTab } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 740px)' });
  const [viewState, setViewState] = useState({});
  const [mapStyle, setMapStyle] = useState(streetStyle);
  const [styleChecked, setStyleChecked] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);
  const [collapseLegend, setCollapseLegend] = useState(isMobile);
  const [layers, setLayers] = useState({
    deviceInsightWarning: { id: 'deviceInsightWarning', name: 'No Recent Insights', visible: false },
    displaySyncWarning: { id: 'displaySyncWarning', name: 'No Recent Camera Triggers', visible: false },
    spypointCableError: { id: 'spypointCableError', name: 'Spypoint Cable Error', visible: false },
    batteryLevelWarning: { id: 'batteryLevelWarning', name: 'Battery Level Warning', visible: false },
    insightCount: { id: 'insightCount', name: 'Insight Count', visible: false },
  });
  const [mapRef, setMapRef] = useState(null);

  const features = points.map((p) => {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7); // Subtract 7 days
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [p.longitude, p.latitude],
      },
      properties: {
        insightCount: p.insightMetrics ? p.insightMetrics.count : 0,
        insightCountText: p.insightMetrics ? p.insightMetrics.count > 999 ? '>999' : p.insightMetrics.count : 0,
        longitude: p.longitude,
        latitude: p.latitude,
        deviceName: p.device && getDisplayName(p.device),
        location: p.device && p.device.location_name,
        deviceId: p.device && p.device.device_id,
        lastSeen: p.device && p.device.last_seen,
        insightWarning: p.device && p.device.last_seen && (new Date(p.device.last_seen)).valueOf() < lastWeek.valueOf() ? true : false,
        spypointCableError: p?.device?.summary?.startup_data && p?.device?.summary?.startup_data & 1 === 1 ? true : false,
        syncWarning: p?.device?.summary?.image_count_trend && p?.device?.summary?.image_count_trend.length >= 3 && p?.device?.summary?.image_count_trend[0].image_count <= p?.device?.summary?.image_count_trend[2].image_count,
        batteryLevelWarning: p?.device?.battery_voltage && p?.device?.battery_voltage < MINIMUM_BATTERY_VOLTAGE,
        displayInsights: layers.insightCount.visible,
        displayInsightWarning: layers.deviceInsightWarning.visible,
        displaySpypointCableError: layers.spypointCableError.visible,
        displaySyncWarning: layers.displaySyncWarning.visible,
        displayBatteryLevelWarning: layers.batteryLevelWarning.visible,
        mostRecentClassName: p.insightMetrics && p.insightMetrics.mostRecentInsight ? p.insightMetrics.mostRecentInsight.class_name : null,
        mostRecentTimestamp: p.insightMetrics && p.insightMetrics.mostRecentInsight ? p.insightMetrics.mostRecentInsight.time_stamp : null,
      },
    };
  });

  const onLoad = () => {
    const [minLng, minLat, maxLng, maxLat] = turf.bbox({
      type: 'FeatureCollection',
      features: features,
    });

    mapRef.loadImage(
      'images/sd-card-icon.png',
      (error, image) => {
        if (error) throw error;
        mapRef.addImage('spypoint-cable-error', image);
      });

    mapRef.loadImage(
      'images/no-image-photography-icon.png',
      (error, image) => {
        if (error) throw error;
        mapRef.addImage('no-image', image);
      });

    mapRef.loadImage(
      'images/low-battery-icon.png',
      (error, image) => {
        if (error) throw error;
        mapRef.addImage('low-battery', image);
      });

    mapRef.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      { padding: isMobile ? 30 : 150, duration: 1000, maxZoom: 10 }
    );

    if (setTab) {
      mapRef.on('mouseover', clusterLayer.id, () => {
        mapRef.getCanvas().style.cursor = 'pointer'
      })
      mapRef.on('mouseleave', clusterLayer.id, () => {
        mapRef.getCanvas().style.cursor = ''
      })
      mapRef.on('mouseover', unclusteredPointLayer.id, () => {
        mapRef.getCanvas().style.cursor = 'pointer'
      })
      mapRef.on('mouseleave', unclusteredPointLayer.id, () => {
        mapRef.getCanvas().style.cursor = ''
      })
    }
  }

  useEffect(() => {
    if (mapRef) {
      onLoad();
    }
  }, [mapRef]);

  const onClick = event => {
    if (mapRef) {
      if (event.features && event.features.length > 0 && setTab) {
        const feature = event.features[0];
        if (feature.layer.id === clusterLayer.id) {
          const clusterId = feature.properties.cluster_id;

          const mapboxSource = mapRef.getSource('cameras');

          mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
              return;
            }
            mapRef.easeTo({
              center: feature.geometry.coordinates,
              zoom,
              duration: 1000
            });
          });
        } else if (
          feature.layer.id === unclusteredPointLayer.id ||
          feature.layer.id === unclusteredPointLayerInsideText.id ||
          feature.layer.id === unclusteredPointLayerCountCircle.id ||
          feature.layer.id === unclusteredPointLayerCount.id
        ) {
          setPopupInfo(feature.properties);
        }
      }
    }
  };

  const toggleLayerVisibility = (layerId) => {
    const updatedLayers = { ...layers };
    updatedLayers[layerId] = { ...updatedLayers[layerId], visible: !updatedLayers[layerId].visible }
    setLayers(updatedLayers);
  };

  const geoData = {
    type: "FeatureCollection",
    features
  }

  const handleStyleChange = () => {
    const checked = !styleChecked;
    if (checked) {
      setMapStyle(streetStyle);
    } else {
      setMapStyle(outdoorStyle);
    }
    setStyleChecked(checked);
  };

  const handleMove = (viewState) => {
    setViewState(viewState)
  }

  return (
    <Map
      {...viewState}
      onMove={evt => handleMove(evt.viewState)}
      onLoad={(event) => setMapRef(event.target)}
      style={{ width: "100%", height: "100%" }}
      interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
      mapboxAccessToken={MAPBOX_TOKEN}
      renderWorldCopies={false}
      mapStyle={mapStyle}
      onClick={onClick}
      onRender={(event) => event.target.resize()}
    >
      <Source
        id="cameras"
        type="geojson"
        data={geoData}
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={35}
        clusterProperties={{
          insightWarning: ['any', ['get', 'insightWarning']],
          displayInsightWarning: ['any', ['get', 'displayInsightWarning']],
          spypointCableError: ['any', ['get', 'spypointCableError']],
          displaySpypointCableError: ['any', ['get', 'displaySpypointCableError']],
          syncWarning: ['any', ['get', 'syncWarning']],
          displaySyncWarning: ['any', ['get', 'displaySyncWarning']],
          batteryLevelWarning: ['any', ['get', 'batteryLevelWarning']],
          displayBatteryLevelWarning: ['any', ['get', 'displayBatteryLevelWarning']],
        }}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...clusteredPointLayerWarning} />
        <Layer {...clusteredPointLayerWarningText} />
        <Layer {...clusteredPointLayerSpypointCircle} />
        <Layer {...clusteredPointLayerSpypoint} />
        <Layer {...clusteredPointLayerSyncErrorCircle} />
        <Layer {...clusteredPointLayerSyncError} />
        <Layer {...clusteredPointLayerBatteryWarningCircle} />
        <Layer {...clusteredPointLayerBatteryWarning} />
        <Layer {...unclusteredPointLayer} />
        <Layer {...unclusteredPointLayerInsideText} />
        <Layer {...unclusteredPointLayerCountCircle} />
        <Layer {...unclusteredPointLayerCount} />
        <Layer {...unclusteredPointLayerWarning} />
        <Layer {...unclusteredPointLayerWarningText} />
        <Layer {...unclusteredPointLayerSpypointCircle} />
        <Layer {...unclusteredPointLayerSpypoint} />
        <Layer {...unclusteredPointLayerSyncErrorCircle} />
        <Layer {...unclusteredPointLayerSyncError} />
        <Layer {...unclusteredPointLayerBatteryWarningCircle} />
        <Layer {...unclusteredPointLayerBatteryWarning} />
      </Source>

      {popupInfo && (
        <Popup
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          onClose={() => setPopupInfo(null)}
        >
          <DeviceView
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            deviceId={popupInfo.deviceId}
            insightCount={popupInfo.insightCount}
            deviceName={popupInfo.deviceName}
            location={popupInfo.location}
            mostRecentClassName={popupInfo.mostRecentClassName}
            mostRecentTimestamp={popupInfo.mostRecentTimestamp}
            setTab={setTab}
            lastSeen={popupInfo.lastSeen}
            insightWarning={popupInfo.insightWarning}
            spypointCableError={popupInfo.spypointCableError}
            syncWarning={popupInfo.syncWarning}
            batteryLevelWarning={popupInfo.batteryLevelWarning}
          />
        </Popup>
      )}

      <ScaleControl />
      <NavigationControl
        position='bottom-right'
      />
      <FullscreenControl />
      <div className={classes.mapActionMenu}>
        <Paper className={classes.legend}>
          <div className={classes.topElements}>
          <div className={classes.centerIcons}>
            <MapIcon color="action" />
            <Switch
              checked={styleChecked}
              onChange={handleStyleChange}
              color="default"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <SatelliteIcon color="action" />
          </div>
          <button
            className={classes.collapseButton}
            onClick={() => {
              setCollapseLegend(!collapseLegend);
            }}
          >
            {collapseLegend ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </button>
          </div>
          {
            !collapseLegend &&
            (<div className={classes.layers}>
              <Typography variant="h6">Layer Legend</Typography>
              <div className={classes.options}>
                {Object.values(layers).map((layer) => (
                  <FormControlLabel
                    key={layer.id}
                    control={
                      <Checkbox
                        checked={layer.visible}
                        onChange={() => toggleLayerVisibility(layer.id)}
                        name={layer.id}
                      />
                    }
                    label={layer.name}
                  />
                ))}
              </div>
            </div>)
          }
        </Paper>
      </div>
    </Map>
  );
}

export default withStyles(styles)(MapView);