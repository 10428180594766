export const createInsightCollectionFromInsights = (insights) => {
  const insightMap = {};
  const keySet = new Set();
  for (const insight of insights) {
    const collectionId = insight.collection_id.toString();
    if (!keySet.has(collectionId)) {
      insightMap[collectionId] = {...insight};
      insightMap[collectionId].insights = [];
      keySet.add(collectionId);
    }
    insightMap[collectionId].insights.push(insight);
  }
  const collections = Object.values(insightMap);
  for (const collection of collections) {
    const collectionData = getCollectionData(collection);
    collection.confidence = collectionData.confidence;
    collection.class_name = collectionData.className;
    collection.class_list = collectionData.classList;
    collection.insight_status = collection.insights.reduce((final, current) => final === current ? final : undefined, collection.insights[0].insight_status);
    collection.insight_id = collectionData.insightId
  }
  return collections;
}

// Weighted averages used
export const getCollectionData = (collection) => {
  const classMap = {};
  for (const insight of collection.insights) {
    if (!Object.keys(classMap).includes(insight.class_name)) {
      classMap[insight.class_name] = [];
    }
    classMap[insight.class_name].push({insightId: insight.insight_id, confidence: insight.confidence});
  }
  const orderedClasses = Object.entries(classMap).map(entry => { return {className: entry[0], confidence: Math.round(entry[1].map(i => i.confidence).reduce((sum, a) => a + sum, 0) / entry[1].length)}}).sort((a, b) => b.confidence - a.confidence);
  const className = orderedClasses[0].className;
  const insightId = classMap[className].reduce((max, current) => current.score > max.score ? current : max, classMap[className][0]).insightId;
  return {
    confidence: orderedClasses[0].confidence,
    className,
    insightId,
    classList: orderedClasses
  };
}
