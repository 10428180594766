import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserInsightDialog from './UserInsightDialog';

const styles = (theme) => ({});

function MobileInsight(props) {

  const { classes, insight } = props;

  const rowKey = insight.insight_id + ':' + insight.device_id;


  return (
    <div key={rowKey}>
      <UserInsightDialog isMobile={true} insight={insight}/>
    </div>
  );
}

export default withStyles(styles)(MobileInsight);
