/**
 * This doc is a central location for different constants or enums that are
 * relevant at different locations of the website codebase in order to
 * maintain consistency.
 */

// The different markings allowed on the label
const CYCLE_STEPS = [];
// 05 min steps from 5 to 55 min
for (let min = 5; min < 60; min += 5) CYCLE_STEPS.push({ value: min });
// 30 min steps from 1 hour to 11.5 hours
for (let hour = 1; hour < 12; hour += 0.5)
  CYCLE_STEPS.push({ value: hour * 60 });
// 60 min steps from 12 hours to 48 hours
for (let hour = 12; hour <= 48; hour += 1)
  CYCLE_STEPS.push({ value: hour * 60 });

// The different markings allowed on the label
const SYNC_STEPS = [];
// 05 min steps from 5 to 55 min
for (let min = 5; min < 60; min += 5) SYNC_STEPS.push({ value: min });
// 30 min steps from 1 hour to 11.5 hours
for (let hour = 1; hour < 12; hour += 0.5)
  SYNC_STEPS.push({ value: hour * 60 });
// 60 min steps from 12 hours to 48 hours
for (let hour = 12; hour <= 48; hour += 1)
  SYNC_STEPS.push({ value: hour * 60 });

/**
 * The text to diplay for the No Group Option
 */
const NO_GROUP_TEXT = 'No Group';

const MINIMUM_BATTERY_VOLTAGE = 3.2;

/**
 * The names for each page in the webpage
 * @enum {string}
 */
const Page = {
  USER_INSIGHTS: 'userInsights',
  DASHBOARD: 'dashboard',
  DEVICE: 'device',
  ACCOUNT: 'account',
  MODELS: 'models',
  MAP: 'map',
  MARKETPLACE: 'marketplace',
  DOCUMENTATION: 'documentation',
  ADMIN: 'admin',
  GROUPS: 'groups',
  INTERNAL: 'internal',
  DEVICE_UPDATES: 'deviceUpdates'
};

const IntegrationPlatforms = {
  GUNDI: 'gundi',
  EMAIL: 'email',
  SMS: 'sms'
}

const NotificationConstants = {
  ALL: 'all',
  NONE: 'none'
}

const AlertSettings = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
}

const DigestSettings = {
  DAILY: 'daily'
}

const DeviceHeaders = {
  DEVICE_NAME: 'Device Name',
  STATUS: 'Status',
  LOCATION: 'Location',
  RELEASE: 'Release',
  LAST_SEEN: 'Last Seen',
  TIME_ZONE: 'Time Zone',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  MEMORY: 'Memory Remaining',
  DEPLOYED_MODELS: 'Deployed Models',
  GROUP: 'Group',
  DETAILS: 'Details',
  EDIT: 'Edit',
  DESCRIPTION: 'Description',
  LOCATION_DESCRIPTION: 'Location Description'
};

const HeaderDataTypes = {
  STRING: 'string',
  DATE: 'date',
  NUMBER: 'number',
  PERCENT: 'percent',
  ELEMENT: 'element',
  BUTTONS: 'buttons',
  LIST: 'list'
};

const BulkUpdateStatus = {
  PROCESSING: 1,
  COMPLETED: 2,
  WAITING: 3,
  BLOCKED: 4
}

const ActionsRanking = {
  FIRST: "acknowledge",
  SECOND: null,
  THIRD: "favorite",
  FOURTH: "archive"
}

const DEVICE_UPDATE_STATUS = {
	SUCCESS: "success",
	FAILED: "failed",
	PENDING: "pending",
	DROPPED: "dropped",
}

const IMAGE_NORMALIZAION_METHODS = {
	RESIZE: "resize",
	PADDING: "padding",
}

const FEATURES = {
  COLLECTIONS: "insight_collections"
}

export { MINIMUM_BATTERY_VOLTAGE, DEVICE_UPDATE_STATUS, CYCLE_STEPS, SYNC_STEPS, NO_GROUP_TEXT, Page, IntegrationPlatforms, NotificationConstants, AlertSettings, DigestSettings, DeviceHeaders, HeaderDataTypes, BulkUpdateStatus, ActionsRanking, IMAGE_NORMALIZAION_METHODS, FEATURES };
