import React, { useEffect, useState, useRef } from 'react';
import { createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { getDisplayName } from '../../util/deviceHelpers';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import BulkUpdatesToolbar from './BulkUpdatesToolbar';
import InsightView from './InsightView';
import ButtonFilters from '../Common/Table/ButtonFilters';
import BasicModalNoButton from '../Common/Modals/BasicModalNoButton';
import InsightMapDialog from './InsightMapDialog';
import UserInsightDialog from './UserInsightDialog';
import MobileInsight from './MobileInsight';
import { HeaderDataTypes, ActionsRanking } from '../../util/constants';
import { getFormattedTimeString } from '../../util/time';
import { FormControlLabel, Switch, Grid } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { set } from 'lodash';
import { filter } from 'lodash';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const insightHeaders = [
  {
    id: 'class_name',
    type: HeaderDataTypes.STRING,
    label: 'Primary Class Name',
    display: true,
    compareWith: "all_class_names",
    comparisonFailurePrefix: "Less Likely:"
  },
  {
    id: 'all_class_names',
    type: HeaderDataTypes.LIST,
    label: 'Possible Class Names',
    display: false,
    info: "Possible class names from most likely to least likely.",
  },
  {
    id: 'alg_id',
    type: HeaderDataTypes.NUMBER,
    label: 'Model ID',
    display: false
  },
  {
    id: 'device_id',
    type: HeaderDataTypes.NUMBER,
    label: 'Device ID',
    display: false
  },
  {
    id: 'device_name',
    type: HeaderDataTypes.STRING,
    label: 'Device Name',
    display: true
  },
  {
    id: 'confidence',
    type: HeaderDataTypes.PERCENT,
    label: 'Confidence',
    display: true
  },
  {
    id: 'time_stamp',
    type: HeaderDataTypes.DATE,
    label: 'Received At (UTC)',
    display: true,
    info: "When our servers received the insight."
  },
  {
    id: 'file_ctime',
    type: HeaderDataTypes.DATE,
    label: 'Camera Timestamp',
    display: true,
    info: "All insights prior to Sept. 6th 2023 will display time based on when we received the insight. All insights after will display time based on the insight's file creation time set by the trail camera."
  },
  {
    id: 'comms_source',
    type: HeaderDataTypes.STRING,
    label: 'Comms Source',
    display: true
  },
  {
    id: 'lat',
    type: HeaderDataTypes.NUMBER,
    label: 'Latitude',
    display: true
  },
  {
    id: 'long',
    type: HeaderDataTypes.NUMBER,
    label: 'Longitude',
    display: true
  },
  {
    id: 'map_button',
    type: HeaderDataTypes.ELEMENT,
    label: 'Map',
    display: false
  },
  {
    id: 'image',
    type: HeaderDataTypes.ELEMENT,
    label: 'Image',
    display: false
  },
  {
    id: 'groups',
    type: HeaderDataTypes.STRING,
    label: 'Device Groups',
    display: false
  },
  {
    id: 'status',
    type: HeaderDataTypes.BUTTONS,
    label: 'Actions',
    display: true
  }
];

const useStyles = makeStyles({
  cellIcons: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconInactive: {
    color: '#6A6A6A',
  },
  iconActive: {
    color: '#3893BE',
  }
});

export default function InsightsTable(props) {
  const { filters, setTab } = props;
  const [processedInsights, setProcessedInsights] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [defaultOrderHeader, setDefaultOrderHeader] = useState("");
  const [defaultFilters, setDefaultFilters] = useState([]);
  const userInsightsData = useSelector((state) => state.userInsights.userInsightsData);
  const groupData = useSelector((state) => state.group.groupData);
  const features = useSelector((state) => state.feature.features);
  const [modalData, setModalData] = useState({ open: false });
  const [groupMap, setGroupMap] = useState({});
  const [insightMap, setInsightMap] = useState({});
  const [showImages, setShowImages] = React.useState(insightHeaders[11].display);
  const [showArchived, setShowArchived] = React.useState(false);
  const actionsIndex = useRef(0);
  const [bulkUpdate, setBulkUpdate] = useState(localStorage.getItem('bulkUpdate') ? JSON.parse(localStorage.getItem('bulkUpdate')) : []);
  const [actions, setActions] = useState(localStorage.getItem('actions') ? JSON.parse(localStorage.getItem('actions')) : []);
  const [idMap, setIdMap] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [bulkUpdateHistory, setBulkUpdateHistory] = useState([bulkUpdate]);
  const [actionsHistory, setActionsHistory] = useState([]);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  const popperProps = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -15],
        },
      },
    ],
  }

  function initialStatuses() {
    let statuses = [];

    rowData.forEach((row) => {
      statuses[row.id] = row.insight.insight_status
    });

    return statuses;
  }

  function UnsavedChangesSnackbar(props) {
    const { bulkUpdate, clearBulkUpdate } = props;
    return (
      <Snackbar
        open={bulkUpdate.length > 0}
        message={bulkUpdate.length === 1 ? `${bulkUpdate.length} unsaved action` : `${bulkUpdate.length} unsaved actions`}
        action={
          <Button onClick={clearBulkUpdate} color="inherit" size="small">
            Cancel
          </Button>
        }
      />
    )
  }

  function ActionsButtons(props) {
    const { row } = props;

    return (
      <Box className={classes.cellIcons}>
        <Tooltip title="Acknowledge" PopperProps={popperProps}>
          <IconButton onClick={(event) => handleActionClick(event, [row.id], "acknowledge")}>
            <VisibilityIcon className={actionActive(row.id) === "acknowledge" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive" PopperProps={popperProps}>
          <IconButton onClick={(event) => handleActionClick(event, [row.id], "archive")}>
            <ArchiveIcon className={actionActive(row.id) === "archive" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Favorite" PopperProps={popperProps}>
          <IconButton onClick={(event) => handleActionClick(event, [row.id], "favorite")}>
            <StarIcon className={actionActive(row.id) === "favorite" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const handleImageToggle = (event) => {
    setShowImages(!showImages)
    insightHeaders[12].display = !insightHeaders[12].display;
  }

  const handleArchivedToggle = (event) => {
    setShowArchived(!showArchived);
  }

  const handleClick = async (event, row) => {
    let newModalData = {};
    newModalData = {
      open: true,
      title: `Class Name: ${row.insight && row.insight.class_name}`,
      content: (<InsightView row={row} insight={row.insight} ActionsButtons={ActionsButtons} />)
    };
    setModalData(newModalData);
  }

  const getMobileElement = (row) => {
    return (
      <MobileInsight insight={row.insight} />
    )
  }

  const handleClose = () => {
    setModalData({ open: false });
  }

  const getSetFilters = (headers) => {
    if (filters && filters.length > 0) {
      return filters.map((filter) => {
        const filteredHeaders = headers.filter((header) => header.id === filter.id);
        if (filteredHeaders.length > 0) {
          return {
            header: filteredHeaders[0],
            headerId: filter.id,
            value: filter.value
          }
        }
      });
    }
  }


  // bulk updates

  function updateBulkUpdate(ids, action) {
    let updatedBulkUpdate = [...bulkUpdate];
    let currentUpdates = bulkUpdate.length;
    let i = 0;
    let updated = false;

    for (i; i < ids.length; i++) {
      const id = ids[i];
      const currentInsight = idMap[id];
      if (!currentInsight) return;
      const filteredBulkUpdate = updatedBulkUpdate.filter(update => update.id !== id);

      const insightList = currentInsight.collection ? currentInsight.insights : [currentInsight]

      for (const insight of insightList) {
        // cases where bulk update can be updated
        if ((insight.insight_status !== action) && ((currentUpdates < 1000) || filteredBulkUpdate.length < updatedBulkUpdate.length)) {
          // case: insight has changed and we have less than 1000 updates or the update is already in the bulk update
          if (filteredBulkUpdate.length === updatedBulkUpdate.length) {
            currentUpdates += 1;
          }

          updatedBulkUpdate = filteredBulkUpdate;
          updatedBulkUpdate.push({ id: id, insightId: insight.insight_id, deviceId: insight.device_id, insightStatus: action });
          updated = true;
        } else if (insight.insight_status === action) {
          // case: insight has not changed
          updatedBulkUpdate = filteredBulkUpdate;
          currentUpdates -= 1;
          updated = true;
        }

      }

      if (currentUpdates >= 1000) {
        setOpen(true);
        break;
      }
    }

    // increment i if there was an update
    if (updated) i += 1;

    return [updatedBulkUpdate, i];
  };

  const handleActionClick = (event, ids, action) => {
    event.stopPropagation();
    const updatedActions = [...actions];
    const newAction = updatedActions[ids[0]] === action ? null : action;
    const result = updateBulkUpdate(ids, newAction);
    const updatedBulkUpdate = result[0]
    const lastIndex = result[1]

    for (let i = 0; i < lastIndex; i++) {
      const id = ids[i]

      if (updatedActions[id] === action) {
        updatedActions[id] = null;
      } else {
        updatedActions[id] = action;
      }

    }

    // update history
    if (lastIndex > 0) {
      actionsIndex.current += 1;
      setActionsHistory([...actionsHistory.slice(0, actionsIndex.current), updatedActions]);
      setBulkUpdateHistory([...bulkUpdateHistory.slice(0, actionsIndex.current), updatedBulkUpdate]);
    }

    setActions(updatedActions);
    setBulkUpdate(updatedBulkUpdate);
  };

  const handleUndoClick = () => {
    if (actionsIndex.current > 0) {
      actionsIndex.current = actionsIndex.current - 1;
      setActions(actionsHistory[actionsIndex.current]);
      setBulkUpdate(bulkUpdateHistory[actionsIndex.current]);
    }
  };

  const handloRedoClick = () => {
    if (actionsIndex.current < actionsHistory.length - 1) {
      actionsIndex.current = actionsIndex.current + 1;
      setActions(actionsHistory[actionsIndex.current]);
      setBulkUpdate(bulkUpdateHistory[actionsIndex.current]);
    }
  };

  const clearBulkUpdate = () => {
    actionsIndex.current += 1;
    setActionsHistory([...actionsHistory.slice(0, actionsIndex.current), []]);
    setBulkUpdateHistory([...bulkUpdateHistory.slice(0, actionsIndex.current), []]);

    setSelected([]);
    setSelectAll(false);
    setBulkUpdate([]);
    setActions(initialStatuses());
    localStorage.removeItem('bulkUpdate');
    localStorage.removeItem('actions');
  }

  const handleAlertClose = (event) => {
    setOpen(false);
  }

  const canUndo = actionsIndex.current > 0;
  const canRedo = actionsIndex.current < actionsHistory.length - 1;

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const actionActive = (id) => actions[id];

  useEffect(() => {
    setRowData(processedInsights);
    setHeaders(insightHeaders);
    setDefaultFilters(getSetFilters(insightHeaders));

    setDefaultOrderHeader("status");
  }, [features, processedInsights]);

  useEffect(() => {
    if (groupData) {
      const groupDevice = {};
      for (const group of groupData) {
        for (const device of group.devices) {
          if (!(device in groupDevice)) {
            groupDevice[device] = [];
          }
          groupDevice[device].push(group.group_name);
        }
      }
      setGroupMap(groupDevice);
    }
  }, [groupData]);

  useEffect(() => {
    if (userInsightsData) {
      setProcessedInsights(
        userInsightsData.map((insight, index) => {
          return {
            id: index,
            class_name: insight.class_name.toLowerCase(),
            alg_id: insight.alg_id,
            insight: insight,
            device_id: insight.device_id,
            device_name: getDisplayName(insight),
            confidence: insight.confidence,
            file_ctime: getFormattedTimeString(insight.file_ctime),
            time_stamp: getFormattedTimeString(insight.time_stamp),
            comms_source: insight.comms_source,
            lat: insight.latitude,
            long: insight.longitude,
            map_button: { element: (<InsightMapDialog disabled={!insight.latitude || !insight.longitude} insight={insight} />), present: !!insight.latitude && !!insight.longitude },
            image: { element: (<UserInsightDialog isMobile={false} insight={insight} />), present: !!insight.photoUrl },
            groups: insight.device_id in groupMap ? groupMap[insight.device_id].join(", ") : "None",
            all_class_names: insight.collection ? insight.class_list.map(cl => cl.className.toLowerCase()) : [insight.class_name.toLowerCase()]
          }
        })
      );
    }
  }, [groupMap, userInsightsData]);

  useEffect(() => {
    if (userInsightsData) {
      const tempMap = {};
      userInsightsData.forEach((insight) => {
        tempMap[`${insight.insight_id}_${insight.device_id}`] = insight;
      });
      setInsightMap(tempMap);
    }
  }, [userInsightsData]);

  // bulk updates
  useEffect(() => {
    const idMap = {}

    rowData.forEach((row) => {
      if (!row.insight) return;
      idMap[row.id] = {
        insight_id: row.insight.insight_id,
        device_id: row.device_id,
        insight_status: row.insight.insight_status,
        insights: row.insight.insights,
        collection: row.insight.collection
      };
    });
    setIdMap(idMap)
  }, [rowData]);

  useEffect(() => {
    if (localStorage.getItem('bulkUpdate') !== null && JSON.parse(localStorage.getItem('bulkUpdate')).length === 0) {
      localStorage.setItem("actions", JSON.stringify(initialStatuses()));
    }
    setActions(JSON.parse(localStorage.getItem('actions')));
  }, [rowData]);

  useEffect(() => {
    localStorage.setItem('bulkUpdate', JSON.stringify(bulkUpdate));
  }, [bulkUpdate]);

  return (
    <>
      {
        !isMobile ?
          <Grid container justifyContent="flex-end">
            <FormControlLabel checked={showImages} onChange={handleImageToggle} control={<Switch />} label="Show images" />
            <FormControlLabel checked={showArchived} onChange={handleArchivedToggle} control={<Switch />} label="Show archived" />
          </Grid> : null
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={handleAlertClose}
          severity={"info"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          You've hit the 1000 insight update limit. Please save your current changes and start another update.
        </Alert>
      </Snackbar>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader={defaultOrderHeader}
        headers={headers}
        rowData={rowData}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
        initialFilters={defaultFilters}
        getMobileElement={getMobileElement}
        swipeable={false}
        showArchived={showArchived}
        checkBoxes={true}
        handleArchivedToggle={handleArchivedToggle}
        AdditionalToolbar={<BulkUpdatesToolbar bulkUpdate={bulkUpdate} setBulkUpdate={setBulkUpdate} setSelectedRows={setSelected} selectedRows={selected} onActionClick={handleActionClick} actions={actions} canUndo={canUndo} canRedo={canRedo} onUndoClick={handleUndoClick} onRedoClick={handloRedoClick} setSelectAll={setSelectAll} />}
        handleActionClick={handleActionClick}
        handleUndoClick={handleUndoClick}
        handloRedoClick={handloRedoClick}
        canUndo={canUndo}
        canRedo={canRedo}
        actions={actions}
        bulkUpdate={bulkUpdate}
        setBulkUpdate={setBulkUpdate}
        actionsHistory={actionsHistory}
        setActionsHistory={setActionsHistory}
        initialStatuses={initialStatuses}
        setSelectAll={setSelectAll}
        selectAll={selectAll}
        setSelected={setSelected}
        selected={selected}
        isSelected={isSelected}
        actionActive={actionActive}
        UnsavedChangesSnackbar={<UnsavedChangesSnackbar bulkUpdate={bulkUpdate} clearBulkUpdate={clearBulkUpdate} />}
        ActionsButtons={ActionsButtons}
        ButtonFilters={ButtonFilters}
        buttonRanking={ActionsRanking}
      />
      <BasicModalNoButton
        fullWidth
        title={modalData.title}
        content={modalData.content}
        confirmText='Close'
        noCancel
        confirmAction={handleClose}
        passedOpen={modalData.open}
      />
    </>

  );
}
