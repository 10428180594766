import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import {
  getInsightImagePromise,
} from '../../actions/userInsightsActions';
import CountUp from 'react-countup';
import { CircularProgress, Typography } from '@material-ui/core';
import { getInsightTime } from '../../util/time';

import GeneralSwipeableView from '../Common/ContentContainers/GeneralSwipeableView';
import InsightMapDialog from './InsightMapDialog';

const styles = (theme) => ({
  largeImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  photoDiv: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  border: {
    height: '100%',
    width: '100%',
    border: '2px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px'
  },
  button: {
    margin: '0px 5px'
  },
  text: {
    margin: 0
  },
  gridContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: 8,
    columnGap: 16,
  },
  gridHeader: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  buttonContainer: { display: 'flex', flexDirection: 'row', rowGap: 8 },
  mapContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  latLongContainer: { marginRight: 20 },
  centerColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  meterContainer: {
    maxWidth: 100,
    margin: 'auto',
    width: '30%',
    borderRadius: 10,
    height: '70%',
    backgroundColor: 'rgb(99,99,99,0.1)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  }
});

const InsightView = (props) => {
  const { classes, row, insight, ActionsButtons } = props;
  const [imageLoading, setImageLoading] = useState(!!insight.photoUrl);
  const [images, setImages] = useState([]);

  const [localInsight, setLocalInsight] = useState(insight);


  const loadImage = async () => {
    setImageLoading(true);
    const downloadedImages = [];
    const insights = localInsight.collection ? localInsight.insights : [localInsight];
    for (const insight of insights) {
      if (!!insight.photoUrl) {
        downloadedImages.push(getInsightImagePromise(insight.device_id, insight.insight_id, insight.alg_id, insight.class_name, insight.photoUrl));
      }
    }
    setImages(await Promise.all(downloadedImages));
    setImageLoading(false);
  };

  useEffect(() => {
    if (!!insight.photoUrl) {
      loadImage();
    }
    setLocalInsight(insight);
  }, [insight])

  return (
    <div className={classes.gridContainer}>
      <div className={classes.gridHeader}>
        <p className={classes.text}>
          <b>Insight Id: </b> {localInsight.insight_id}
        </p>

        <p className={classes.text}>
          <b>Most Likely Species: </b> {localInsight.class_name}
        </p>

        {
          localInsight && localInsight.class_list && localInsight.class_list.length > 1 &&
          (<p className={classes.text}>
            <b>All Possible Species (Most Likely to Least Likely): </b><br/> {localInsight && localInsight.class_list.map(a => a.className).join(", ")}
          </p>)
        }
        
        <p className={classes.text}>
          <b>Camera Timestamp: </b>
          {moment(getInsightTime(localInsight))
            .tz('UTC')
            .format('MMM Do YYYY, h:mm a')}{' '}
        </p>

        {
          localInsight && localInsight.collection &&
          (<p className={classes.text}>
            <b>Collection Window Length: </b> {Math.abs(((new Date(localInsight.window_start)).getTime()/1000) - ((new Date(localInsight.window_end)).getTime()/1000)) + 1} seconds
          </p>)
        }

        {!!localInsight.latitude && !!localInsight.longitude && (
          <div className={classes.mapContainer}>
            <div className={classes.latLongContainer}>
              <p className={classes.text}>
                <b>Location: </b>{`${localInsight.latitude}, ${localInsight.longitude}`}
              </p>
            </div>
            <InsightMapDialog
              latitude={localInsight.latitude}
              longitude={localInsight.longitude}
              insight={localInsight}
              disabled={!localInsight || !localInsight.latitude || !localInsight.longitude}
            />
          </div>
        )}
        <div className={classes.largeImage}>
          {!!localInsight.photoUrl ?
            (imageLoading ?
              <CircularProgress disableShrink /> :
              <div>
                {<GeneralSwipeableView content={images.map((image, index) => {
                  return (<img
                    className={classes.photoDiv}
                    src={`data:image/jpeg;base64,${image}`}
                    width={300}
                    height={300}
                    id={`large-image${index}`}
                  />)
                })} />
                }
              </div>
            ) :
            <div className={classes.border}>
              <Typography>Image does not exist</Typography>
            </div>
          }
        </div>
        {ActionsButtons ? <ActionsButtons row={row} /> : null}
      </div>

      <div className={classes.centerColumn}>
        <div className={classes.centerColumn}>
          <p>Confidence</p>

          <p>
            {localInsight.is_from_lora ? (
              <CountUp
                start={0}
                end={localInsight.group_confidence}
                duration={1}
                delay={0}
              />
            ) : (
              <CountUp
                start={0}
                end={localInsight.confidence}
                duration={1}
                delay={0}
              />
            )}
            %
          </p>
        </div>
        <div className={`meter ${classes.meterContainer}`}>
          <span
            style={{
              bottom: 0,
              height: `${localInsight.is_from_lora
                  ? localInsight.group_confidence
                  : localInsight.confidence
                }%`,
            }}
          >
            <span class="progress"></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(InsightView);
