import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Checkbox, IconButton, TextField, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { createRelease, fetchAlgReleases } from '../../../actions/internalActions';
import { IMAGE_NORMALIZAION_METHODS } from '../../../util/constants';

const useStyles = makeStyles({
  addComponentButton: {
    marginTop: "10px"
  },
  icon: {
    marginLeft: "20px",
    flexShrink: 0
  },
  borderBottom: {
    width: "100%",
    borderBottom: 1
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingLeft: 0
  }
});

const blankComponent = {
  algId: null,
  saveCroppedImages: false,
  saveBBImages: false,
  isIntermediate: false,
  downstreamConfigs: [],
  ignoredClasses: [],
  bypassClasses: []
}

export default function AddReleaseModal(props) {
  const { algs, open, handleClose } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [components, setComponents] = useState([]);
  const [classOptionMap, setClassOptionMap] = useState({});
  const [hasFields, setHasFields] = useState({});
  const [algIds, setAlgIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSave = async () => {

    const processedComponents = [];
    const failedAlgs = [];
    for (const component of components) {
      if (!hasFields[component.algId]) {
        failedAlgs.push(component.algId);
      }
      processedComponents.push({
        algId: parseInt(component.algId),
        saveCroppedImages: component.saveCroppedImages,
        saveBBImages: component.saveBBImages,
        isIntermediate: component.isIntermediate,
        downstreamConfigs: component.downstreamConfigs.map((config) => parseInt(config)),
        ignoredClasses: component.ignoredClasses.map((label) => classOptionMap[component.algId].filter((definition) => definition.label === label)[0].class_id),
        bypassClasses: component.bypassClasses.map((label) => classOptionMap[component.algId].filter((definition) => definition.label === label)[0].class_id),
        imageNormalizationMethod: component.imageNormalizationMethod
      })
    }
    if (failedAlgs.length > 0) {
      setErrorMessage("Some models used are not correctly formatted for release or use frameworks that are not support (YOLOv5 and 2StageClassifier only). Confirm each model has an alg_path, alg_bucket, and md5_checksum in the Algs tab. If they don't, update them from the same tab.");
      return;
    }
    const response = await createRelease({
      name,
      description,
      models: processedComponents
    });
    
    if (!response) {
      setErrorMessage("Release failed to create. Double check all values are filled in correctly or contact Brandon Asheim.")
    } else {
      dispatch(fetchAlgReleases());
      handleClose();
    }
  }

  const addComponent = () => {
    setComponents([...components, JSON.parse(JSON.stringify(blankComponent))]);
  }

  const removeComponent = (index) => {
    const componentCopy = [...components];
    componentCopy.splice(index, 1);
    setComponents(componentCopy);
  }

  const updateComponent = (index, key, value) => {
    const componentCopy = [...components];
    componentCopy[index][key] = value;
    setComponents(componentCopy);
  }

  useEffect(() => {
    if (algs) {
      const algsMap = {};
      const reviewMap = {};
      for (const alg of algs) {
        if (alg.class_definitions) {
          algsMap[alg.alg_id.toString()] = alg.class_definitions;
        } else {
          algsMap[alg.alg_id.toString()] = [];
        }
        
        algsMap[alg.alg_id.toString()].push({
          class_id: 99,
          label: "empty"
        });

        reviewMap[alg.alg_id.toString()] = alg.md5_checksum && alg.alg_bucket && alg.alg_path && ["2StageClassifier", "YOLOv5"].includes(alg.framework);
      }
      setAlgIds(algs.map((alg) => alg.alg_id.toString()));
      setHasFields(reviewMap);
      setClassOptionMap(algsMap);
    }
  }, [algs]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Release</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            rows={4}
            inputProps={{
              maxLength: 100
            }}
            margin="dense"
            label="Name"
            name="name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
          />
        </Grid>
        <Grid container spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            rows={4}
            inputProps={{
              maxLength: 100
            }}
            margin="dense"
            label="Description"
            name="description"
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
          />
        </Grid>
        <Grid container spacing={2}>
          {
            components && components.length > 0 ? components.map((component, index) => {
              return (<Grid container item spacing={2}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    rows={4}
                    inputProps={{
                      maxLength: 100
                    }}
                    margin="dense"
                    label="Alg ID"
                    name="algId"
                    value={component.algId}
                    onChange={(event) => updateComponent(index, "algId", event.currentTarget.value)}
                  />
                  <div className={classes.checkboxContainer}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={component.isIntermediate}
                      onChange={(event) => updateComponent(index, "isIntermediate", event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant='body1'>Is an intermediate model?</Typography>
                  </div>
                  <div className={classes.checkboxContainer}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={component.saveBBImages}
                      onChange={(event) => updateComponent(index, "saveBBImages", event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant='body1'>Should device save bounding box images for this model?</Typography>
                  </div>
                  <div className={classes.checkboxContainer}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={component.saveCroppedImages}
                      onChange={(event) => updateComponent(index, "saveCroppedImages", event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant='body1'>Should device save cropped images for this model?</Typography>
                  </div>
                  <Autocomplete 
                    multiple
                    onChange={(event, value) => updateComponent(index, "downstreamConfigs", value)}
                    value={component.downstreamConfigs}
                    options={algIds}
                    renderInput={(params) => <TextField {...params} label="Downstream Configs" />}
                  />
                  <Autocomplete 
                    multiple
                    onChange={(event, value) => updateComponent(index, "ignoredClasses", value)}
                    value={component.ignoredClasses}
                    options={(component.algId || component.algId === 0) && Object.keys(classOptionMap).includes(component.algId) ? classOptionMap[component.algId].map((option) => option.label) : []}
                    renderInput={(params) => <TextField {...params} label="Ignored Class Names" />}
                  />
                  <Autocomplete 
                    multiple
                    onChange={(event, value) => updateComponent(index, "bypassClasses", value)}
                    value={component.bypassClasses}
                    options={(component.algId || component.algId === 0) && Object.keys(classOptionMap).includes(component.algId) ? classOptionMap[component.algId].map((option) => option.label) : []}
                    renderInput={(params) => <TextField {...params} label="Bypass Class Names" />}
                  />
                  <Autocomplete 
                    single
                    onChange={(event, value) => updateComponent(index, "imageNormalizationMethod", value)}
                    value={component.imageNormalizationMethod}
                    options={Object.values(IMAGE_NORMALIZAION_METHODS)}
                    defaultValue={IMAGE_NORMALIZAION_METHODS.RESIZE}
                    renderInput={(params) => <TextField {...params} label="Image Normalization Methods" />}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton className={classes.icon} onClick={() => removeComponent(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>);
            }) : []
          }
          <Button onClick={addComponent} variant="outlined" className={classes.addComponentButton}>
            <span>
              Add Model
            </span>
          </Button>
        </Grid>
        {
          errorMessage && errorMessage.length > 0 && 
          <Typography>{errorMessage}</Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
