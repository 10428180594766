import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import InsightsTable from '../UserInsights/insightstable';
import { useLazyLoadSelector } from '../../util/redux';
import { fetchUserInsights } from '../../actions/userInsightsActions';
import { useSelector } from 'react-redux';
import { fetchGroups } from '../../actions/groupActions';

const styles = (theme) => ({});

const UserInsights = (props) => {
  useLazyLoadSelector((state) => state.userInsights.userInsightsData, fetchUserInsights, [], false);
  useLazyLoadSelector((state) => state.group.groupData, fetchGroups, [], false);
  const loadingInsights = useSelector((state) => state.userInsights.userInsightInfoLoading || false);
  const loadingGroups = useSelector((state) => state.group.loadingGroups || false);

  const content = (
    <HomePageWrapper loading={loadingInsights || loadingGroups}>
      <div>
        <h1>Sentinel Insights</h1>
        <InsightsTable filters={props?.pageProps?.filters} setTab={props?.setTab} />
      </div>
    </HomePageWrapper>
  );
  return content;
};

export default withStyles(styles)(UserInsights);
