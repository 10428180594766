// React Imports
import React, { useEffect, useState } from 'react';
// DO NOT REMOVE MapPage import...Not sure why, but it will break the construction of the map page...
import MapPage from './MapPage';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Snackbar, makeStyles, Paper, Typography } from '@material-ui/core';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import { fetchUserInsights } from '../../actions/userInsightsActions';
import { useLazyLoadSelector } from '../../util/redux';
import InsightsByClass from '../Dashboard/Widgets/InsightsByClass';
import { fetchDevice } from '../../actions/deviceActions';
import { useSelector } from 'react-redux';
import InsightsVsImages from '../Dashboard/Widgets/InsightsVsImages';
import DashboardToolbar from '../Dashboard/Common/DashboardToolbar';
import { getDisplayName } from '../../util/deviceHelpers';
import HoursSinceLastTable from '../Dashboard/Widgets/HoursSinceLastTable';
import HoursSinceLastGraph from '../Dashboard/Widgets/HoursSinceLastGraph';
import InsightRecievedHourByDay from '../Dashboard/Widgets/InsightRecievedHourByDay';
import DeviceAlerts from '../Dashboard/Widgets/DeviceAlerts';
import { fetchGroups } from '../../actions/groupActions';
import InsightsLast2Weeks from '../Dashboard/Widgets/InsightsLast2Weeks';
import { HeaderDataTypes } from '../../util/constants';
import { filterData } from '../../util/filters';
import { createTheme } from '@mui/material';
import { fetchUserFeatures } from '../../actions/featureActions';
import TotalInsights from '../Dashboard/Widgets/TotalInsights';
import TotalClasses from '../Dashboard/Widgets/TotalClasses';
import InsightStatuses from '../Dashboard/Widgets/InsightStatuses';
import Heatmap from '../Dashboard/Widgets/Heatmap';
import InsightDelay from '../Dashboard/Widgets/InsightDelay';
import TotalDevices from '../Dashboard/Widgets/TotalDevices';
import CommsSource from '../Dashboard/Widgets/CommsSource';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      sm: 625
    },
  },
});


const useStyles = makeStyles({
  pdf: {
    [theme_bp.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme_bp.breakpoints.up('sm')]: {
      padding: 10,
    }
  },
});

const widgets = [
  {
    id: 'total_insights',
    title: 'Total Insights',
    component: 'TotalInsights',
    size: 1,
    display: true
  },
  {
    id: 'total_classes',
    title: 'Total Classes',
    component: 'TotalClasses',
    size: 1,
    display: true
  },
  {
    id: 'total_devices',
    title: 'Total Devices',
    component: 'TotalDevices',
    size: 1,
    display: false
  },
  {
    id: 'insight_statuses',
    title: 'Insight Statuses',
    component: 'InsightStatuses',
    size: 2,
    display: true
  },
  {
    id: 'hours_since_last_table',
    title: 'Hours Since Last Device Communication (Table)',
    component: 'HoursSinceLastTable',
    size: 2,
    display: true
  },
  {
    id: 'comms_source',
    title: 'Comms Source',
    component: 'CommsSource',
    size: 2,
    display: false
  },
  {
    id: 'hours_since_last_graph',
    title: 'Hours Since Last Device Communication (Graph)',
    component: 'HoursSinceLastGraph',
    size: 3,
    display: true
  },
  {
    id: 'insights_by_class',
    title: 'Insights By Class',
    component: 'InsightsByClass',
    size: 3,
    display: true
  },
  {
    id: 'insights_vs_images',
    title: 'Insights vs Images Processed',
    component: 'InsightsVsImages',
    size: 3,
    display: true
  },
  {
    id: 'insight_received_hour_by_day',
    title: 'Insight Received Hour By Day',
    component: 'InsightRecievedHourByDay',
    size: 3,
    display: true
  },
  {
    id: 'insights_last_2_weeks',
    title: 'Insights Last 2 Weeks',
    component: 'InsightsLast2Weeks',
    size: 3,
    display: true
  },
  {
    id: 'heatmap',
    title: 'Heatmap',
    component: 'Heatmap',
    size: 3,
    display: true
  },
  {
    id: 'insight_delay',
    title: 'Insight Delay',
    component: 'InsightDelay',
    size: 3,
    display: true,
    restrictFilters: true
  },

  {
    id: 'device_alerts',
    title: 'Device Alerts',
    component: 'DeviceAlerts',
    size: 4,
    display: true
  }
];

const insightKey = "insight";

const widgetMap = {
  'TotalInsights': TotalInsights,
  'TotalClasses': TotalClasses,
  'InsightStatuses': InsightStatuses,
  'HoursSinceLastTable': HoursSinceLastTable,
  'HoursSinceLastGraph': HoursSinceLastGraph,
  'InsightsByClass': InsightsByClass,
  'InsightsVsImages': InsightsVsImages,
  'InsightRecievedHourByDay': InsightRecievedHourByDay,
  'InsightsLast2Weeks': InsightsLast2Weeks,
  'DeviceAlerts': DeviceAlerts,
  'Heatmap': Heatmap,
  'InsightDelay': InsightDelay,
  'TotalDevices': TotalDevices,
  'CommsSource': CommsSource
}

const Dashboard = (props) => {
  const { setTab } = props;

  const classes = useStyles();

  const [filteredData, setFilteredData] = useState([]);
  const [deviceFilteredData, setDeviceFilteredData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [groupMap, setGroupMap] = useState({});
  const [selectedWidgets, setSelectedWidgets] = useState(localStorage.getItem('selectedWidgets') ? JSON.parse(localStorage.getItem("selectedWidgets")) : widgets);
  const [showArchived, setShowArchived] = useState(true);
  const [filterAction, setFilterAction] = useState('archive');

  const headers = [
    {
      id: 'class_name',
      type: HeaderDataTypes.STRING,
      label: 'Primary Class Name',
      restrict_to: ['insight']
    },
    {
      id: 'all_class_names',
      type: HeaderDataTypes.STRING,
      label: 'Possible Class Names',
      restrict_to: ['insight']
    },
    {
      id: 'display_name',
      type: HeaderDataTypes.STRING,
      label: 'Device Name'
    },
    {
      id: 'groups',
      type: HeaderDataTypes.STRING,
      label: 'Device Group'
    },
    {
      id: 'time_stamp',
      type: HeaderDataTypes.DATE,
      label: 'Received At (UTC)',
      restrict_to: ['insight']
    },
    {
      id: 'file_ctime',
      type: HeaderDataTypes.DATE,
      label: 'Timestamp',
      restrict_to: ['insight']
    }
  ];

  const groupData = useLazyLoadSelector((state) => state.group.groupData, fetchGroups, [], false);
  const devices = useLazyLoadSelector((state) => state.device.deviceData, fetchDevice, [], false);
  const insights = useLazyLoadSelector((state) => state.userInsights.userInsightsData, fetchUserInsights, [], false);
  const loadingInsights = useSelector((state) => state.userInsights.userInsightInfoLoading || false);
  const loadingDevices = useSelector((state) => state.device.loadingDevices || false);

  const isLoading = () => {
    return !!loadingDevices || !!loadingInsights;
  }

  useEffect(() => {
    if (processedData) {
      let data = [...processedData]
      let deviceData = [...processedData]
      if (filters) {
        data = filterData(filters, data);
        deviceData = filterData(filters.filter((filter) => filter?.header?.id === 'display_name'), deviceData);
      }
      setFilteredData(data);
      setDeviceFilteredData(deviceData);
    }
  }, [processedData, filters]);

  useEffect(() => {
    if (devices && groupData) {
      const groupDevice = {};
      for (const group of groupData) {
        for (const device of group.devices) {
          if (!(device in groupDevice)) {
            groupDevice[device] = [];
          }
          groupDevice[device].push(group.group_name);
        }
      }
      setGroupMap(groupDevice);
    }
  }, [devices, groupData]);

  useEffect(() => {
    let data = [];
    if (devices) {
      data = [
        ...data,
        ...devices.map((device, index) => {
          return {
            id: index,
            device,
            data_type: "device",
            device_id: device.device_id,
            display_name: getDisplayName(device),
            location_name: device.location_name,
            location_description: device.location_description,
            last_seen: (new Date(device.last_seen)).toISOString().split(".")[0],
            lat: device.latitude,
            long: device.longitude,
            cycle_time: device.cycle_time,
            trigger_number: device.trigger_number,
            memory_remaining: device.memory_remaining || device?.summary?.space_remaining || null,
            images_processed: device.images_processed || device?.summary?.image_count || null,
            cycle_count: device?.summary?.cycle_count || null,
            startup_data: device?.summary?.startup_data || 0,
            groups: device.device_id in groupMap ? groupMap[device.device_id].join(", ") : "None",
          }
        })
      ];
    }
    if (insights) {
      data = [
        ...data,
        ...insights.map((insight, index) => {
          return {
            id: index,
            data_type: "insight",
            class_name: insight.class_name.toLowerCase(),
            all_class_names: insight.collection ? insight.class_list.map(cl => cl.className.toLowerCase()).join(", ") : "",
            alg_id: insight.alg_id,
            insight: insight,
            device_id: insight.device_id,
            display_name: getDisplayName(insight),
            confidence: insight.confidence,
            file_ctime: (new Date(insight.file_ctime)).toISOString().split(".")[0],
            time_stamp: (new Date(insight.time_stamp)).toISOString().split(".")[0],
            comms_source: insight.comms_source,
            lat: insight.latitude,
            long: insight.longitude,
            groups: insight.device_id in groupMap ? groupMap[insight.device_id].join(",") : "None",
          }
        })
      ];
    }

   
    setProcessedData(data);
  }, [devices, insights, groupMap]);

  useEffect(() => {
    if (showArchived) {
      setFilterAction('');
    } else {
      setFilterAction('archive');
    }
  }, [showArchived]);

  return (
    <HomePageWrapper>
      <div>
        <h1>Dashboard</h1>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <DashboardToolbar headers={headers} updateFilters={setFilters} filters={filters} selectedWidgets={selectedWidgets} setSelectedWidgets={setSelectedWidgets} showArchived={showArchived} setShowArchived={setShowArchived} />
        </Grid>
      </Grid>
      {
        selectedWidgets.filter((widget) => widget.display).length === 0 ?
          <Grid container spacing={1}>
              <Typography variant="h6" align="center" style={{ 'marginLeft' : '2%' }}>No widgets selected</Typography>
          </Grid> : null
      }
      <div id="pdf-container" className={classes.pdf}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2}>
            {
              selectedWidgets.sort((a, b) => a.size - b.size).map((widget) => {
                if (widget.display && widget.size === 1) {
                  const Component = widgetMap[widget.component];
                  return (
                    <Grid item>
                      <Component isLoading={isLoading()} insights={filteredData.filter((d) => d.data_type === insightKey && d?.insight.insight_status !== filterAction)} devices={filteredData.filter((d) => d.data_type === "device")} />
                    </Grid>
                  )
                }
              })
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4} spacing={1}>
            {
              selectedWidgets.sort((a, b) => a.size - b.size).map((widget) => {
                if (widget.display && widget.size === 2) {
                  const Component = widgetMap[widget.component];
                  return (
                    <Grid item>
                      <Component isLoading={isLoading()} insights={filteredData.filter((d) => d.data_type === insightKey && d?.insight.insight_status !== filterAction)} devices={filteredData.filter((d) => d.data_type === "device")} />
                    </Grid>
                  )
                }
              })
            }
          </Grid>
          {
            selectedWidgets.sort((a, b) => a.size - b.size).map((widget) => {
              if (widget.display && widget.size === 3) {
                const Component = widgetMap[widget.component];
                const data = widget.restrictFilters ? deviceFilteredData : filteredData;
                return (
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid item>
                      <Component isLoading={isLoading()} insights={data.filter((d) => d.data_type === insightKey && d?.insight.insight_status !== filterAction)} devices={data.filter((d) => d.data_type === "device")} />
                    </Grid>
                  </Grid>

                )
              }
            })
          }
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            {
              selectedWidgets.sort((a, b) => a.size - b.size).map((widget) => {
                if (widget.display && widget.size === 4) {
                  const Component = widgetMap[widget.component];
                  return (
                    <Grid item>
                      <Component isLoading={isLoading()} insights={processedData.filter((d) => d.data_type === insightKey)} devices={filteredData.filter((d) => d.data_type === "device")} />
                    </Grid>
                  )
                }
              })
            }
          </Grid>
        </Grid>
      </div>
    </HomePageWrapper>
  );
};

export default Dashboard;
