import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MapView from '../Map/MapView';
import { useSelector } from 'react-redux';
import { fetchDevice } from '../../actions/deviceActions';
import { useLazyLoadSelector } from '../../util/redux';
import { fetchUserInsights } from '../../actions/userInsightsActions';
import CenteredCircularProgress from '../Common/CenteredCircularProgress';

const styles = (theme) => ({
  center: {
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  centerLoading: { 
    minHeight: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
});

const MapPage = (props) => {
  const { classes, setTab } = props;
  const [points, setPoints] = useState([]);
  const devices = useLazyLoadSelector((state) => state.device.deviceData, fetchDevice, [], false);
  const insights = useLazyLoadSelector((state) => state.userInsights.userInsightsData, fetchUserInsights, [], false);
  const loadingInsights = useSelector((state) => state.userInsights.userInsightInfoLoading || false);
  const loadingDevices = useSelector((state) => state.device.loadingDevices || false);

  useEffect(() => {
    if (devices && insights) {

      const data = insights;

      const insightMetrics = {};
      data
      .sort((a, b) => {
        const timeStampA = new Date(a.time_stamp);
        const timeStampB = new Date(b.time_stamp);
        return timeStampB.getTime() - timeStampA.getTime();
      })
      .forEach((insight) => {
        if (!insightMetrics[insight.device_id]) {
          insightMetrics[insight.device_id] = {
            count: 0,
            mostRecentInsight: insight
          };
        }
        insightMetrics[insight.device_id].count += 1;
      });

      const newPoints = devices
      .filter((d) => d && d.latitude && d.longitude)
      .filter((d) => d.latitude >= -90 && d.latitude <= 90 && d.longitude >= -180 && d.longitude <= 180)
      .map((d) => {
        return {
          latitude: d.latitude, 
          longitude: d.longitude,
          device: d,
          insightMetrics: insightMetrics[d.device_id]
        };
      });
      setPoints(newPoints);
    }
  }, [devices, insights]);


  return(
    loadingDevices || loadingInsights ? <div className={classes.centerLoading}><CenteredCircularProgress size={150}/></div> :
    points.length > 0 ? <MapView points={points} setTab={setTab}  /> :
    <h1 className={classes.center}>
    No Devices Available
  </h1>
  )
};

export default withStyles(styles)(MapPage);
