import axios from "axios";

export const getAllDataFromPagination = async (countUrl, paginatedDataUrl, size) => {
    const countResponse = await axios.get(countUrl);
    const maxCount = countResponse.data.message.count;
    const requestCount = Math.ceil(maxCount / size);
    const requests = [];
    for (let i = 0; i < requestCount; i++) {
        requests.push(axios.get(`${paginatedDataUrl}?limit=${size}&offset=${(i * size)}`));
    }
    const responses = await Promise.all(requests);
    return responses.reduce((accumulator, response) => {
        return [...accumulator, ...response.data.message]
    }, []);
}