import axios from 'axios';
import { server } from '../actions/backend-url';
import { getPhotoUrl } from '../util/photo';
import { FEATURES } from '../util/constants';
import { createInsightCollectionFromInsights } from '../util/collectionHelpers';
import { getAllDataFromPagination } from '../util/paginationHelpers';

export const FETCH_USER_INSIGHTS_BEGIN = 'FETCH_USER_INSIGHTS_BEGIN';
export const FETCH_USER_INSIGHTS_SUCCESS = 'FETCH_USER_INSIGHTS_SUCCESS';
export const FETCH_USER_INSIGHTS_FAILURE = 'FETCH_USER_INSIGHTS_FAILURE';

export const FETCH_USER_INSIGHTS_GRAPH_BEGIN =
  'FETCH_USER_INSIGHTS_GRAPH_BEGIN';
export const FETCH_USER_INSIGHTS_GRAPH_SUCCESS =
  'FETCH_USER_INSIGHTS_GRAPH_SUCCESS';
export const FETCH_USER_INSIGHTS_GRAPH_FAILURE =
  'FETCH_USER_INSIGHTS_GRAPH_FAILURE';

export const UPDATE_USER_CONFIRM_BEGIN = 'UPDATE_USER_CONFIRM_BEGIN';
export const UPDATE_USER_CONFIRM_SUCCESS = 'UPDATE_USER_CONFIRM_SUCCESS';
export const UPDATE_USER_CONFIRM_FAILURE = 'UPDATE_USER_CONFIRM_FAILURE';

export const FETCH_INSIGHT_IMAGE_BEGIN = 'FETCH_INSIGHT_IMAGE_BEGIN';
export const FETCH_INSIGHT_IMAGE_SUCCESS = 'FETCH_INSIGHT_IMAGE_SUCCESS';
export const FETCH_INSIGHT_IMAGE_FAILURE = 'FETCH_INSIGHT_IMAGE_FAILURE';

export const FETCH_INSIGHT_IMAGES_EXIST_BEGIN = 'FETCH_INSIGHT_IMAGES_EXIST_BEGIN';
export const FETCH_INSIGHT_IMAGES_EXIST_SUCCESS = 'FETCH_INSIGHT_IMAGES_EXIST_SUCCESS';
export const FETCH_INSIGHT_IMAGES_EXIST_FAILURE = 'FETCH_INSIGHT_IMAGES_EXIST_FAILURE';

export const CREATE_BULK_UPDATE_BEGIN = 'CREATE_BULK_UPDATE_BEGIN';
export const CREATE_BULK_UPDATE_SUCCESS = 'CREATE_BULK_UPDATE_SUCCESS';
export const CREATE_BULK_UPDATE_FAILURE = 'CREATE_BULK_UPDATE_FAILURE';

export const FETCH_BULK_UPDATE_STATUS_BEGIN = 'FETCH_BULK_UPDATE_STATUS_BEGIN';
export const FETCH_BULK_UPDATE_STATUS_SUCCESS = 'FETCH_BULK_UPDATE_STATUS_SUCCESS';
export const FETCH_BULK_UPDATE_STATUS_FAILURE = 'FETCH_BULK_UPDATE_STATUS_FAILURE';


export const fetchUserInsightsBegin = () => {
  return {
    type: FETCH_USER_INSIGHTS_BEGIN,
  };
};

export const fetchUserInsightsSuccess = (insights, photosMap) => {
  return {
    type: FETCH_USER_INSIGHTS_SUCCESS,
    payload: { insights, photosMap },
  };
};

export const fetchUserInsightsFailure = (error) => {
  return {
    type: FETCH_USER_INSIGHTS_FAILURE,
    payload: { error },
  };
};

const handleUserInsightsFetch = async (dispatch) => {
  dispatch(fetchUserInsightsBegin());
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    const featuresResponse = await axios.get(`${server}/api/features`);
    let insights;
    if (featuresResponse.data.message.filter(i => i.key === FEATURES.COLLECTIONS && i.enabled).length > 0) {
      const insightsResponse = await getAllDataFromPagination(`${server}/api/insights/count`, `${server}/api/collections/paginated`, 10000);
      insights = createInsightCollectionFromInsights(insightsResponse);
    } else {
      insights = await getAllDataFromPagination(`${server}/api/insights/count`, `${server}/api/insights/paginated`, 10000);
    }
    const photosResponse = await axios.get(`${server}/api/insights/photos`);
    const photosMap = {};
    for (const photo of photosResponse.data.message) {
      photosMap[`${photo["insightId"]}_${photo["deviceId"]}_${photo["table"]}`] = photo;
    }
    dispatch(fetchUserInsightsSuccess(insights, photosMap));
  } catch (error) {
    dispatch(fetchUserInsightsFailure(error));
  }
}

export function fetchUserInsights() {
  return (dispatch) => {
    handleUserInsightsFetch(dispatch);
  };
}

export const fetchUserInsightsGraphBegin = () => {
  return {
    type: FETCH_USER_INSIGHTS_GRAPH_BEGIN,
  };
};

export const fetchUserInsightsGraphSuccess = (response) => {
  return {
    type: FETCH_USER_INSIGHTS_GRAPH_SUCCESS,
    payload: { response },
  };
};

export const fetchUserInsightsGraphFailure = (error) => {
  return {
    type: FETCH_USER_INSIGHTS_GRAPH_FAILURE,
    payload: { error },
  };
};

export function fetchUserInsightsGraph() {
  return (dispatch) => {
    dispatch(fetchUserInsightsGraphBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(
        `${server}/api/insights/overview`
      )
      .then((response) => {
        dispatch(fetchUserInsightsGraphSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchUserInsightsGraphFailure(error)));
  };
}

export const updateUserConfirmSuccess = (insightId, deviceId, value, isStaging) => {
  return {
    type: UPDATE_USER_CONFIRM_SUCCESS,
    payload: { insightId, deviceId, value, isStaging },
  };
};

export const updateUserConfirmBegin = () => {
  return {
    type: UPDATE_USER_CONFIRM_BEGIN,
  };
};

export const updateUserConfirmFailure = (error) => {
  return {
    type: UPDATE_USER_CONFIRM_FAILURE,
    payload: { error },
  };
};

export function updateUserConfirm(insightIdInput, deviceIdInput, valueInput, table) {
  return (dispatch) => {
    dispatch(updateUserConfirmBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/updateUserConfirmation/`, {
        insightId: insightIdInput,
        deviceId: deviceIdInput,
        value: valueInput,
        isStaging: table === "staging"
      })
      .then((response) => {
        console.log(response);
        dispatch(
          updateUserConfirmSuccess(insightIdInput, deviceIdInput, valueInput, table === "staging")
        );
        return response;
      })
      .catch((error) => dispatch(updateUserConfirmFailure(error)));
  };
}


export const fetchInsightImageBegin = () => {
  return {
    type: FETCH_INSIGHT_IMAGE_BEGIN,
  };
};

export const fetchInsightImageSuccess = (deviceId, insightId, algId, className, table, dataBuffer) => {
  return {
    type: FETCH_INSIGHT_IMAGE_SUCCESS,
    payload: {
      deviceId,
      insightId,
      algId,
      className,
      table,
      dataBuffer
    },
  };
};

export const fetchInsightImageFailure = (error) => {
  return {
    type: FETCH_INSIGHT_IMAGE_FAILURE,
    payload: { error },
  };
};

export const getInsightImage = (deviceId, insightId, algId, className, table, photoUrl) => {
  return (dispatch) => {
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios.get(`${server}/api/photo?thumbnail=true&deviceId=${deviceId}&photoUrl=${encodeURI(photoUrl)}`, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const dataBuffer = Buffer.from(response.data, 'binary').toString('base64');
        dispatch(fetchInsightImageSuccess(deviceId, insightId, algId, className, table, dataBuffer));
        return response;
      })
      .catch((error) => dispatch(fetchInsightImageFailure(error)));
  }
}

export const getInsightImagePromise = async (deviceId, insightId, algId, className, photoUrl = getPhotoUrl(insightId, algId, className)) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  const response = await axios.get(`${server}/api/photo?deviceId=${deviceId}&photoUrl=${encodeURI(photoUrl)}`, {
    responseType: 'arraybuffer',
  });
  return Buffer.from(response.data, 'binary').toString('base64');
}

export const createBulkUpdateBegin = () => {
  return {
    type: CREATE_BULK_UPDATE_BEGIN,
  };
}

export const createBulkUpdateSuccess = (bulkId) => {
  return {
    type: CREATE_BULK_UPDATE_SUCCESS,
    payload: { bulkId },
  };
}

export const createBulkUpdateFailure = (error) => {
  return {
    type: CREATE_BULK_UPDATE_FAILURE,
    payload: { error },
  };
}

export function createBulkUpdate(insights) {
  return (dispatch) => {
    dispatch(createBulkUpdateBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/updates/`, {
        insights: insights,
      })
      .then((response) => {
        dispatch(createBulkUpdateSuccess(response.data.message.bulkId));
        return response;
      })
      .catch((error) => dispatch(createBulkUpdateFailure(error)));
  };
}

export const fetchBulkUpdateStatusBegin = () => {
  return {
    type: FETCH_BULK_UPDATE_STATUS_BEGIN,
  };
}

export const fetchBulkUpdateStatusSuccess = (message, status) => {
  return {
    type: FETCH_BULK_UPDATE_STATUS_SUCCESS,
    payload: { message, status },
  };
}

export const fetchBulkUpdateStatusFailure = (error) => {
  return {
    type: FETCH_BULK_UPDATE_STATUS_FAILURE,
    payload: { error },
  };
}

export function fetchBulkUpdateStatus(bulkId) {
  return (dispatch) => {
    if (bulkId) {
      dispatch(fetchBulkUpdateStatusBegin());
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      return axios
        .get(`${server}/api/updates?bulk_id=${bulkId}`)
        .then((response) => {
          dispatch(fetchBulkUpdateStatusSuccess(response.data.message.message, response.data.message.status));
          return response;
        })
        .catch((error) => dispatch(fetchBulkUpdateStatusFailure(error)));
    }
  }
}