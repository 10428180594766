import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';

import { useSelector } from 'react-redux';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import { DEVICE_UPDATE_STATUS, HeaderDataTypes } from '../../util/constants';
import UpdateDropModal from './updateDropModal';
import CreateUpdateModal from './createUpdateModal';
import PendingIcon from '@material-ui/icons/CachedOutlined';
import PassedIcon from '@material-ui/icons/CheckCircleOutline';
import FailedIcon from '@material-ui/icons/HighlightOffOutlined';
import NoActionIcon from '@material-ui/icons/RemoveCircleOutline';
import { Tooltip } from '@material-ui/core';


const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles({

});

export default function DeviceUpdatesTable(props) {
  const [processedUpdates, setProcessedUpdates] = useState([]);
  const [modalData, setModalData] = useState({ open: false});
  const deviceUpdates = useSelector((state) => state.deviceUpdates.updates);
  const allReleases = useSelector((state) => state.deviceUpdates.allReleases);
  const deviceData = useSelector((state) => state.device.deviceData);
  const user = useSelector((state) => state.user);
  

  const handleClick = (event, row) => {
    const newModalData = {
      open: true,
      updateId: row.update_id,
      devices: row.devices,
      status: row.status

    };
    setModalData(newModalData);
  }

  const handleClose = (shouldReload) => {
    setModalData({ open: false });
    if (shouldReload) {
      processUpdates();
    }
  }

  const getUpdateIcon = (update)  => {
    const status = update.status;
    switch(status) {
      case DEVICE_UPDATE_STATUS.SUCCESS: return (<Tooltip title={"Update passed"}><PassedIcon style={{ color: 'green' }} /></Tooltip>);
      case DEVICE_UPDATE_STATUS.FAILED: return (<Tooltip title={"Update failed"}><FailedIcon style={{ color: 'red' }} /></Tooltip>);
      case DEVICE_UPDATE_STATUS.PENDING: return (<Tooltip title={"Update pending"}><PendingIcon style={{ color: '#ebc934' }} /></Tooltip>);
      default: return (<Tooltip title={"Update dropped"}><NoActionIcon style={{ color: 'grey' }} /></Tooltip>);
    }
  }

  const defaultHeaders = [
    {
      id: 'overall_status',
      type: HeaderDataTypes.ELEMENT,
      label: 'Overall Status',
      display: true
    },
    {
      id: 'update_id',
      type: HeaderDataTypes.NUMBER,
      label: 'Update ID',
      display: true
    },
    {
      id: 'alg_release_id',
      type: HeaderDataTypes.STRING,
      label: 'Alg Release',
      display: true
    },
    {
      id: 'firmware_version',
      type: HeaderDataTypes.STRING,
      label: 'Firmware Version',
      display: true,
      info: "Firmware Version Tag. Actual version sha changes."
    },
    {
      id: 'created_on',
      type: HeaderDataTypes.DATE,
      label: 'Created On (UTC)',
      display: true
    },
    {
      id: 'cycle_time',
      type: HeaderDataTypes.NUMBER,
      label: 'Cycle Time',
      display: false
    },
    {
      id: 'trigger_number',
      type: HeaderDataTypes.NUMBER,
      label: 'Trigger Number',
      display: false
    },
    {
      id: 'sat_passes',
      type: HeaderDataTypes.STRING,
      label: 'Satellite Pass Windows',
      display: false,
      info: "Only used for Swarm."
    },
    {
      id: 'satellite_fix_time',
      type: HeaderDataTypes.NUMBER,
      label: 'Satellite Fix Time',
      display: false
    },
    {
      id: 'device_count',
      type: HeaderDataTypes.NUMBER,
      label: 'Device Count',
      display: true
    },
    {
      id: 'device_ids',
      type: HeaderDataTypes.STRING,
      label: 'Device IDs',
      display: false
    }
  ];

  useEffect(() => {
    if (deviceUpdates) {
      processUpdates();
    }
  }, [deviceUpdates]);

  const processUpdates = () => {
    setProcessedUpdates(
      [...deviceUpdates.map((update, index) => {
        return {
          id: index,
          update_id: update.deviceUpdateId,
          overall_status: {element: getUpdateIcon(update), present: true},
          status: update.status,
          alg_release_id: update.algReleaseId.toString(),
          firmware_version: update.firmwareReleaseTag,
          created_on: update.createdAt,
          cycle_time: update.cycleTime,
          trigger_number: update.triggerNumber,
          sat_passes: update.satellitePasses,
          satellite_fix_time: update.satelliteFixTime,
          device_count: update.deviceIds.length,
          device_ids: update.deviceIds.map((device) => device.deviceId).join(" ,"),
          devices: update.deviceIds,
          no_click: update.status !== DEVICE_UPDATE_STATUS.PENDING 
        }
      })]
    );
  }

  return (
    <>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader="update_id"
        headers={defaultHeaders}
        rowData={processedUpdates}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
        AdditionalToolbar={<CreateUpdateModal allReleases={allReleases} user={user} allDeviceIds={deviceData.map((device) => device.device_id.toString())}/>}
      /> 
      {
        modalData && modalData.updateId && modalData.status === DEVICE_UPDATE_STATUS.PENDING &&
        (<UpdateDropModal
          updateId={modalData.updateId}
          devices={modalData.devices}
          open={modalData.open}
          handleClose={handleClose}
        />)
      }
    </>
  );
}
