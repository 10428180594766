import { HeaderDataTypes } from "./constants";

export const descendingComparator = (a, b, orderBy, headers, actions, buttonRanking) => {
  let tempA;
  let tempB;

  const header = headers.filter((header) => header.id === orderBy);

  if (header.type !== HeaderDataTypes.BUTTONS) {
    tempA = a[orderBy];
    tempB = b[orderBy];
  }

  if (header.length > 0) {
    switch (header[0].type) {
      case HeaderDataTypes.DATE:
        tempA = (new Date(a[orderBy])).getTime();
        tempB = (new Date(b[orderBy])).getTime();
        break;
      case HeaderDataTypes.ELEMENT:
        tempA = a[orderBy]?.present ? 1 : 0;
        tempB = b[orderBy]?.present ? 1 : 0;
        break;
      case HeaderDataTypes.STRING:
        tempA = a[orderBy]?.toLowerCase();
        tempB = b[orderBy]?.toLowerCase();
        break;
      case HeaderDataTypes.LIST:
        tempA = a[orderBy]?.join(", ")?.toLowerCase();
        tempB = b[orderBy]?.join(", ")?.toLowerCase();
        break;
      case HeaderDataTypes.BUTTONS:
        tempA = actions[a.id] === buttonRanking.FIRST ? 1
          : actions[a.id] === buttonRanking.SECOND ? 2
            : actions[a.id] === buttonRanking.THIRD ? 3
              : actions[a.id] === buttonRanking.FOURTH ? 4
                : 5;
        tempB = actions[b.id] === buttonRanking.FIRST ? 1
          : actions[b.id] === buttonRanking.SECOND ? 2
            : actions[b.id] === buttonRanking.THIRD ? 3
              : actions[b.id] === buttonRanking.FOURTH ? 4
                : 5;
    }

  }

  if ((!tempA && tempA !== 0) && (!tempB && tempB !== 0)) {
    return 0;
  } else if ((!tempA && tempA !== 0)) {
    return -1;
  } else if ((!tempB && tempB !== 0)) {
    return 1;
  }

  if (tempB < tempA) {
    return -1;
  }
  if (tempB > tempA) {
    return 1;
  }
  return 0;
}

export const getComparator = (order, orderBy, headers, actions, buttonRanking) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, headers, actions, buttonRanking)
    : (a, b) => -descendingComparator(a, b, orderBy, headers, actions, buttonRanking);
}

export const limitString = (string = '', limit = 0) => {
  if (!string) {
    return string;
  }
  let limitedString = string.substring(0, limit);
  if (string.length > limit) {
    limitedString += "...";
  }
  return limitedString;
}