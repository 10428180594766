export const clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'cameras',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#0093C5', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 30, 100, 30, 750, 40]
  }
};

export const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'cameras',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': ['get', 'point_count'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-allow-overlap': true
  }
};

export const clusteredPointLayerWarning = {
  id: 'clustered-point-warning',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'insightWarning'], ['get', 'displayInsightWarning'], ['has', 'point_count']],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-14, -24]
  }
};

export const clusteredPointLayerWarningText = {
  id: 'clustered-point-warning-text',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'insightWarning'], ['get', 'displayInsightWarning'], ['has', 'point_count']],
  layout: {
    'text-field': '!',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 24,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#000000',
    'text-translate': [-15, -24]
  }
};


export const clusteredPointLayerSpypointCircle = {
  id: 'clustered-point-spypoint-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'spypointCableError'], ['get', 'displaySpypointCableError'], ['has', 'point_count']],
  paint: {
    'circle-color': '#ED0000',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [31, 1]
  }
};

export const clusteredPointLayerSpypoint = {
  id: 'clustered-spypoint-error',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'spypointCableError'], ['get', 'displaySpypointCableError'], ['has', 'point_count']],
  layout: {
    'icon-image': 'spypoint-cable-error', 
    'icon-size': 0.8,
    'icon-offset': [39, 1],
  }
};

export const clusteredPointLayerSyncErrorCircle = {
  id: 'clustered-point-sync-error-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'syncWarning'], ['get', 'displaySyncWarning'], ['has', 'point_count']],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-31, 1]
  }
};

export const clusteredPointLayerSyncError = {
  id: 'clustered-point-sync-error',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'syncWarning'], ['get', 'displaySyncWarning'], ['has', 'point_count']],
  layout: {
    'icon-image': 'no-image', 
    'icon-size': 0.8,
    'icon-offset': [-39, 1],
  }
};

export const clusteredPointLayerBatteryWarningCircle = {
  id: 'clustered-point-battery-warning-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'batteryLevelWarning'], ['get', 'displayBatteryLevelWarning'], ['has', 'point_count']],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-14, 28]
  }
};

export const clusteredPointLayerBatteryWarning = {
  id: 'clustered-point-battery-warning',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'batteryLevelWarning'], ['get', 'displayBatteryLevelWarning'], ['has', 'point_count']],
  layout: {
    'icon-image': 'low-battery', 
    'icon-size': 0.75,
    'icon-offset': [-19, 38],
  }
};

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'cameras',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#AA0000',
    'circle-radius': 20,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#750000',
    'circle-opacity': 0.8
  }
};

export const unclusteredPointLayerInsideText = {
  id: 'unclustered-point-inside-text',
  type: 'symbol',
  source: 'cameras',
  filter:  ['!', ['has', 'point_count']],
  layout: {
    'text-field': ['get', 'deviceId'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 13,
    'text-allow-overlap': true
  },
};

export const unclusteredPointLayerWarning = {
  id: 'unclustered-point-warning',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'insightWarning'], ['get', 'displayInsightWarning'], ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 12,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-13, -22]
  }
};

export const unclusteredPointLayerWarningText = {
  id: 'unclustered-point-warning-text',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'insightWarning'], ['get', 'displayInsightWarning'], ['!', ['has', 'point_count']]],
  layout: {
    'text-field': '!',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 20,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#000000',
    'text-translate': [-14, -22]
  }
};

export const unclusteredPointLayerCount = {
  id: 'unclustered-point-count',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'displayInsights'], ['!', ['has', 'point_count']]],
  layout: {
    'text-field': ['get', 'insightCountText'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#FFFFFF',
    'text-translate': [13, -22]
  }
};

export const unclusteredPointLayerCountCircle = {
  id: 'unclustered-point-count-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'displayInsights'], ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': '#0093C5',
    'circle-radius': 12,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [13, -22]
  }
};

export const unclusteredPointLayerSpypointCircle = {
  id: 'unclustered-point-spypoint-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'spypointCableError'], ['get', 'displaySpypointCableError'], ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': '#ED0000',
    'circle-radius': 12,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [26, 1]
  }
};

export const unclusteredPointLayerSpypoint = {
  id: 'unclustered-spypoint-error',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'spypointCableError'], ['get', 'displaySpypointCableError'], ['!', ['has', 'point_count']]],
  layout: {
    'icon-image': 'spypoint-cable-error', 
    'icon-size': 0.75,
    'icon-offset': [34, 1],
  }
};

export const unclusteredPointLayerSyncErrorCircle = {
  id: 'unclustered-point-sync-error-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'syncWarning'], ['get', 'displaySyncWarning'], ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 12,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-26, 1]
  }
};

export const unclusteredPointLayerSyncError = {
  id: 'unclustered-point-sync-error',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'syncWarning'], ['get', 'displaySyncWarning'], ['!', ['has', 'point_count']]],
  layout: {
    'icon-image': 'no-image', 
    'icon-size': 0.75,
    'icon-offset': [-34, 1],
  }
};

export const unclusteredPointLayerBatteryWarningCircle = {
  id: 'unclustered-point-battery-warning-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'batteryLevelWarning'], ['get', 'displayBatteryLevelWarning'], ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 12,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-13, 24]
  }
};

export const unclusteredPointLayerBatteryWarning = {
  id: 'unclustered-point-battery-warning',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'batteryLevelWarning'], ['get', 'displayBatteryLevelWarning'], ['!', ['has', 'point_count']]],
  layout: {
    'icon-image': 'low-battery', 
    'icon-size': 0.75,
    'icon-offset': [-17, 32],
  }
};
