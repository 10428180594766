import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import MuiListItem from '@material-ui/core/ListItem';

// Icons
import BubbleChart from '@material-ui/icons/BubbleChart';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Notifications from '@material-ui/icons/Notifications';
import RouterIcon from '@material-ui/icons/SettingsRemote';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AppsIcon from '@material-ui/icons/Apps';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Room';
import GroupIcon from '@material-ui/icons/Group';
import StarIcon from '@material-ui/icons/Star';
import ArticleIcon from '@mui/icons-material/Article';
import DownloadIcon from '@mui/icons-material/Download';

import { useSelector } from 'react-redux';

import { hexToRgb, grayColor } from '../../style/style';

import { useTheme } from '@material-ui/core/styles';
import { Page } from '../../util/constants';

const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    color: 'white',
    minWidth: '40px',
  },
  avatar: {
    transition: 'all 0.5s',
    marginTop: 20,
    height: 100,
    width: 100,
  },
  avatarCollapse: {
    [theme.breakpoints.up('sm')]: { height: 50, width: 50 },
  },
  avatarExpand: {
    height: 100,
    width: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    background: 'linear-gradient(60deg, #242425, #2d2d2e)',
    color: 'white',
    elevation: '3',
    boxShadow: '0 5px 20px 0 rgba(0,0,0, 0.50)',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    // margin: 'auto',
    // justifyContent: 'space-around',
  },
  listItem: {
    transition:
      'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, max-width 0.5s, padding-right 0.5s',
    borderRadius: '20px',
    color: 'white',
    cursor: 'pointer',
  },
  listItemExpand: {
    [theme.breakpoints.up('sm')]: { maxWidth: 200 },
    paddingRight: 16,
  },
  listItemCollapse: {
    [theme.breakpoints.up('sm')]: { paddingRight: 0 },
  },
  listText: {
    color: 'white',
    transition: 'max-width 0.5s, opacity 0.25s, max-height 1s',
  },
  listTextStatic: {
    color: 'white',
    transition: 'max-width 0.5s, opacity 0.25s, max-height 1s',
    cursor: 'default'
  },
  listTextExpand: {
    maxHeight: 100,
    maxWidth: 200,
    opacity: 1,
  },
  listTextCollapse: {
    [theme.breakpoints.up('sm')]: { maxHeight: 0, maxWidth: 0, opacity: 0 },
  },
  divider: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  hCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hRule: {
    marginBottom: 8,
    width: '100%',
    height: 1,
    backgroundColor: 'rgba(' + hexToRgb(grayColor[6]) + ', 0.3)',
    transition: 'all 0.5s',
  },
  hRuleExpand: {
    height: 1,
  },
  hRuleCollapse: {
    [theme.breakpoints.up('sm')]: { height: 0 },
  },
  flexDrawer: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      zIndex: 2000,
      height: '100vh',
      width: '100vw',
      transition: 'right 0.5s ease-out',
    },

    height: '100vh',
    position: 'relative',

    display: 'flex',
    scrollbarWidth: 'none',
    overflow: 'auto',
    padding: theme.spacing(3),
    background: 'rgba(0, 0, 0, 0.87)',
  },

  flexDrawerCollapse: {
    [theme.breakpoints.down('sm')]: {
      right: '100vw',
    },
  },
  flexDrawerExpand: {
    right: '0vw',
  },
});

const ListItem = withStyles({
  root: {
    '&$selected': {
      background: 'linear-gradient(60deg, #0093C5, #1195C9)',
      boxShadow: '0 4px 4px 0 rgba(166, 226, 249, 0.2)',
    },
    '&$selected:hover': {
      background: 'linear-gradient(60deg, #0093C5, #1195C9)',
      boxShadow: '0 4px 4px 0 rgba(166, 226, 249, 0.2)',
    },
    '&:hover': {
      background: 'rgba(235, 238, 236, 0.3)',
    },
  },
  selected: {},
})(MuiListItem);

const DrawerDash = (props) => {
  /** @type {{ tab: Page, setTab: React.Dispatch<React.SetStateAction<Page>> }} */
  const { collapseNav, setCollapseNav, classes, tab, setTab, isMobile } = props;

  const user = useSelector((state) => state.user);
  const [pageList, setPageList] = useState([]);
  const theme = useTheme();

  const handleClick = (page) => {
    setTab(page);
    if (isMobile) {
      setCollapseNav(true);
    }
  }

  useEffect(() => {
    if (user && user.userIdSql && user.userIdSql === 84) {
      setPageList([
        [Page.DASHBOARD, 'Dashboard', <DashboardIcon />],
        [Page.DEVICE, 'My Devices', <RouterIcon />],
        [Page.DEVICE_UPDATES, 'Device Updates', <DownloadIcon />],
        [Page.GROUPS, 'Device Groups', <GroupIcon />],
        [Page.MODELS, 'My Models', <BubbleChart />],
        ['userInsights', 'Insights', <Notifications />],
        [Page.MAP, 'Map', <MapIcon />],
        [Page.ADMIN, 'Admin', <EditIcon />],
        [Page.ACCOUNT, 'Account', <AccountBoxIcon />],
        [Page.INTERNAL, 'Internal', <StarIcon/>]
      ]);
    } else if (user && user.userIdSql && user.userIdSql === 131) {
      setPageList([
        [Page.DASHBOARD, 'Dashboard', <DashboardIcon />],
        ['userInsights', 'Insights', <Notifications />],
        [Page.MAP, 'Map', <MapIcon />]
      ]);
    } else {
      setPageList([
        [Page.DASHBOARD, 'Dashboard', <DashboardIcon />],
        [Page.DEVICE, 'My Devices', <RouterIcon />],
        [Page.DEVICE_UPDATES, 'Device Updates', <DownloadIcon />],
        [Page.GROUPS, 'Device Groups', <GroupIcon />],
        [Page.MODELS, 'My Models', <BubbleChart />],
        ['userInsights', 'Insights', <Notifications />],
        [Page.MAP, 'Map', <MapIcon />],
        [Page.ADMIN, 'Admin', <EditIcon />],
        [Page.ACCOUNT, 'Account', <AccountBoxIcon />]
      ]);
    }
  }, [user]);

  const drawer = (
    <div className={classes.drawerContent}>
      <div className={classes.hCenter}>
        <Avatar
          src={user.imageUrl}
          className={`${classes.avatar} ${
            collapseNav ? classes.avatarCollapse : classes.avatarExpand
          }`}
        />
        <ListItemText
          className={`${classes.divider} ${classes.listTextStatic} 
          ${collapseNav ? classes.listTextCollapse : classes.listTextExpand}`}
          primary={`${user.firstName} ${user.lastName}`}
        />
        <div
          className={`${classes.hRule} 
          ${collapseNav ? classes.hRuleCollapse : classes.hRuleExpand}`}
        ></div>
        <div className={classes.hCenter}></div>

        <List>
          {pageList.map((item) => {
            return (
              <ListItem
                className={`${classes.listItem} ${
                  collapseNav
                    ? classes.listItemCollapse
                    : classes.listItemExpand
                }`}
                selected={tab[0] === item[0]}
                onClick={() => handleClick([item[0]])}
              >
                <ListItemIcon className={classes.icon}>{item[2]}</ListItemIcon>
                <ListItemText
                  className={`${classes.listText} ${
                    collapseNav
                      ? classes.listTextCollapse
                      : classes.listTextExpand
                  }`}
                  primary={`${item[1]}`}
                />
              </ListItem>
            );
          })}
          <ListItemText
            className={`${classes.divider} ${classes.listTextStatic} ${
              collapseNav ? classes.listTextCollapse : classes.listTextExpand
            }`}
            primary="GETTING STARTED"
          />
          <div
            className={`${classes.hRule} 
          ${collapseNav ? classes.hRuleCollapse : classes.hRuleExpand}`}
          ></div>

          <ListItem
            className={`${classes.listItem} ${
              collapseNav ? classes.listItemCollapse : classes.listItemExpand
            }`}
            selected={tab[0] === Page.MARKETPLACE}
            onClick={() => handleClick([Page.MARKETPLACE])}
          >
            <ListItemIcon className={classes.icon}>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText
              className={`${classes.listText} ${
                collapseNav ? classes.listTextCollapse : classes.listTextExpand
              }`}
              primary="Model Marketplace"
            />
          </ListItem>
          <a
            href="https://conservation-x-labs-1.gitbook.io/sentinel-multi-language"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            <ListItem
              className={`${classes.listItem} ${
                collapseNav ? classes.listItemCollapse : classes.listItemExpand
              }`}
            >
              <ListItemIcon className={classes.icon}>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText
                className={`${classes.listText} ${
                  collapseNav
                    ? classes.listTextCollapse
                    : classes.listTextExpand
                }`}
                primary="Documentation"
              />
            </ListItem>
          </a>
        </List>
      </div>
    </div>
  );

  return (
    <div
      className={`${classes.flexDrawer} ${
        collapseNav ? classes.flexDrawerCollapse : classes.flexDrawerExpand
      }`}
    >
      {drawer}
    </div>
  );
};

export default withStyles(styles)(DrawerDash);
