import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { createBulkUpdate, fetchBulkUpdateStatus } from '../../actions/userInsightsActions';
import { useDispatch } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ReplayIcon from '@mui/icons-material/Replay'
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { BulkUpdateStatus } from '../../util/constants';
import { makeStyles } from '@material-ui/styles'
import Text from '@mui/material/Typography'


const useStyles = makeStyles({
    spacing: {
        marginRight: "10px"
    },
    icons: {
        color: '#6A6A6A',
        alignItems: 'center',
        width: '100%',
    },
    hover: {
        '&:hover': {
            color: '#9F9F9F',
        },
    },
});

export default function BulkUpdatesToolbar(props) {
    const { bulkUpdate, setBulkUpdate, setSelectedRows, selectedRows, onActionClick, actions, canUndo, canRedo, onUndoClick, onRedoClick, setSelectAll } = props;

    const dispatch = useDispatch();
    const bulkUpdateData = useSelector((state) => state.userInsights.bulkId);
    const bulkUpdateStatusData = useSelector((state) => state.userInsights.bulkUpdateStatusData);
    const [bulkId, setBulkId] = useState(localStorage.getItem("bulkId") ? localStorage.getItem("bulkId") : null);
    const [bulkStatus, setBulkStatus] = useState(localStorage.getItem("bulkStatus") ? localStorage.getItem("bulkStatus") : null);
    const [open, setOpen] = useState(false);
    const [tempBulkUpdate, setTempBulkUpdate] = useState([]);
    const user = useSelector((state) => state.user);
    const classes = useStyles();

    const handleClick = () => {
        if (user && user.userIdSql && user.userIdSql !== 131) {
            dispatch(createBulkUpdate(bulkUpdate));
        }
    }

    const handleClickAcknowledge = async (event) => {
        const filteredRows = selectedRows.filter((row) => actions[row] !== "acknowledge");
        onActionClick(event, filteredRows, "acknowledge");
        setSelectedRows([]);
        setSelectAll(false);
    }

    const handleClickArchive = async (event) => {
        const filteredRows = selectedRows.filter((row) => actions[row] !== "archive");
        onActionClick(event, filteredRows, "archive");
        setSelectedRows([]);
        setSelectAll(false);
    }

    const handleClickFavorite = async (event) => {
        const filteredRows = selectedRows.filter((row) => actions[row] !== "favorite");
        onActionClick(event, filteredRows, "favorite");
        setSelectedRows([]);
        setSelectAll(false);
    }

    const handleClickRemove = async (event) => {
        const filteredRows = selectedRows.filter((row) => actions[row] !== null);
        onActionClick(event, filteredRows, null);
        setSelectedRows([]);
        setSelectAll(false);
    }

    const handleClose = (event) => {
        setOpen(false);
    }

    useEffect(() => {
        // Triggers when new bulk update is sent (bulkId)
        if (bulkUpdateData) {
            setBulkId(bulkUpdateData);
            localStorage.setItem("bulkId", bulkUpdateData);
            dispatch(fetchBulkUpdateStatus(bulkUpdateData));

            setTempBulkUpdate(bulkUpdate);
            setBulkUpdate([]);
        }
    }, [bulkUpdateData]);

    useEffect(() => {
        // Triggers when bulk update status is received
        if (bulkUpdateStatusData && (bulkUpdateData || bulkId)) {
            setBulkStatus(bulkUpdateStatusData.status);
            localStorage.setItem("bulkStatus", bulkUpdateStatusData.status);
            setOpen(true);
        }
    }, [bulkUpdateStatusData]);

    useEffect(() => {
        // Triggers when the bulk update status state updates
        if (bulkStatus === BulkUpdateStatus.COMPLETED) {
            setOpen(true);
            localStorage.removeItem("bulkStatus");
            localStorage.removeItem("bulkId");
            localStorage.removeItem("bulkUpdate");
            localStorage.removeItem("actions");
            window.location.reload();
        } else if (bulkStatus === BulkUpdateStatus.BLOCKED) {
            setOpen(true);
            localStorage.removeItem("bulkStatus");
            localStorage.removeItem("bulkId");
            setBulkUpdate(tempBulkUpdate);
        } else if (bulkStatus === BulkUpdateStatus.PROCESSING || bulkStatus === BulkUpdateStatus.WAITING) {
            setOpen(true);
        }
    }, [bulkStatus]);

    useEffect(() => {
        // Triggers when there is a new bulkId to check the status of
        if (bulkId) {
            dispatch(fetchBulkUpdateStatus(bulkId));
        }
    }, [bulkId]);

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert
                    onClose={handleClose}
                    severity={bulkStatus === BulkUpdateStatus.COMPLETED ? "success" : bulkStatus === BulkUpdateStatus.BLOCKED ? "error" : "info"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {bulkStatus === 1 ? "The bulk update is currently processing..."
                        : bulkStatus === 2 ? "The bulk update has successfully completed!"
                            : bulkStatus === 3 ? "The bulk update is waiting to be processed"
                                : bulkStatus === 4 ? "Someone is trying to do a bulk update, try again later"
                                    : ""}
                </Alert>
            </Snackbar>
            <Box>
                <Box className={classes.icons}>
                    <Tooltip title="Acknowledge all selected rows">
                        <IconButton disabled={selectedRows.length < 1} onClick={event => handleClickAcknowledge(event)}>
                            <VisibilityIcon className={classes.hover} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Archive all selected rows">
                        <IconButton disabled={selectedRows.length < 1} onClick={event => handleClickArchive(event)}>
                            <ArchiveIcon className={classes.hover} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Favorite all selected rows">
                        <IconButton disabled={selectedRows.length < 1} onClick={event => handleClickFavorite(event)}>
                            <StarIcon className={classes.hover} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear all selected rows">
                        <IconButton disabled={selectedRows.length < 1} onClick={event => handleClickRemove(event)}>
                            <NotInterestedIcon className={classes.hover} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <div className={classes.spacing} />
            <Text>
                {
                    selectedRows.length > 1 ? `${selectedRows.length} rows selected` : selectedRows.length === 1 ? `${selectedRows.length} rows selected` : ""
                }
            </Text>
            <div style={{ flex: 1 }} />
            <Tooltip title="Undo Action">
                <IconButton disabled={!canUndo} onClick={onUndoClick}>
                    <ReplayIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Redo Action">
                <IconButton disabled={!canRedo} onClick={onRedoClick}>
                    <ReplayIcon style={{ transform: 'scaleX(-1)' }} />
                </IconButton>
            </Tooltip>
            <div className={classes.spacing} />
            <Button disabled={bulkUpdate.length === 0} variant="outlined" color="primary" onClick={handleClick}>Save Actions</Button>
            <Tooltip title="Refresh bulk update status">
                <IconButton onClick={event => dispatch(fetchBulkUpdateStatus(bulkUpdateData ? bulkUpdateData : bulkId))} disabled={bulkId === null}>
                    <CachedIcon />
                </IconButton>
            </Tooltip>
        </>
    )
}